import Paper from '@mui/material/Paper'
import React from 'react'
import { ReleaseTable } from './ReleaseTable'
import { SidePanel } from './SidePanel'

export const Releases = () => {

  return (
    <div className="d-flex flex-nowrap w-100">
      <div className="side-panel">
        <SidePanel/>
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden', textAlign: 'left' }}>
        <ReleaseTable/>
      </Paper>
    </div>
  )
}
