import React, { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { marketConfigState, selectedMarketsState } from './state-store'
import { queryMarketConfig } from '../BffClient'
import { EconomyData } from './EconomyData'
import { TechnicalData } from './TechnicalData'

export const PccdConfig = () => {

  const [ selectedMarket ] = useRecoilValue(selectedMarketsState)

  const setMarketConfig = useSetRecoilState(marketConfigState)

  useEffect(() => {
    if (!selectedMarket) {
      setMarketConfig({})
      return
    }
    queryMarketConfig('blue', selectedMarket.market)
      .catch(() => queryMarketConfig('blue', 'default'))
      .then(setMarketConfig)
  }, [ selectedMarket, setMarketConfig ])

  return (
    <div className="p-4">
      <EconomyData ecoStandard="wltp"/>
      <EconomyData ecoStandard="nedc"/>
      <EconomyData ecoStandard="ece"/>
      <TechnicalData/>
    </div>
  )
}
