import { tooltipClasses } from '@mui/joy'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import { Options, usePreference } from '../profile/preferences'

export const AvailabilityTip = styled(({ className, tips, onDevPrint, ...props }) => {

  const [ devPrintModel ] = usePreference(Options.DEV_PRINT_MODEL)

  return (
    <Tooltip {...props}
             children={props.children}
             arrow
             placement="top"
             disableHoverListener={!tips.length}
             classes={{ popper: className }}
             title={
               <ul style={{
                 paddingLeft: '0.5rem',
                 margin: '0.5rem',
               }}>
                 {tips.map(tip => <li key={tip}>
                   {tip}
                   <OpenInNewIcon style={{ cursor: 'pointer', marginLeft: '4px' }}
                                  fontSize="small"
                                  onClick={() => window.open(`/faq`)}
                   />
                   {
                     devPrintModel && <LocalPrintshopIcon style={{ cursor: 'pointer', marginLeft: '4px' }}
                                                          fontSize="small"
                                                          onClick={onDevPrint}
                     />
                   }
                 </li>)}
               </ul>
             }
    />
  )
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))
