import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import { loadingState, modelSelectionState, modelsState, searchKeywordState } from './state-store'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ReleaseTableRows } from './ReleaseTableRows'
import { SearchBar } from '../SearchBar'
import { getModelsById } from '../models/model-service'

const getModels = async modelSelection => (await Promise.all(modelSelection.map(getModelsById))).flat()

export const ReleaseTable = () => {

  const modelSelection = useRecoilValue(modelSelectionState)

  const [ loading, setLoading ] = useRecoilState(loadingState)

  const setModels = useSetRecoilState(modelsState)

  useEffect(() => {
    setLoading(true)
    getModels(modelSelection.flat())
      .then(setModels)
      .then(() => setLoading(false))
  }, [ modelSelection, setModels, setLoading ])

  return (
    <>
      <SearchBar keywordState={searchKeywordState}/>
      <TableContainer sx={{ height: 0.97, width: 'auto', paddingRight: '6px', bgcolor: 'WhiteSmoke' }}>
        {loading && <LinearProgress className="mpi-loading-bar"/>}
        {modelSelection.length > 0 &&
          <Table stickyHeader size="small" aria-label="sticky dense table"
                 sx={{ width: 'auto', '& thead th:nth-of-type(1)': { minWidth: '152px' } }}
          >
            <TableHead id="release-table-head">
              <TableRow>
                <TableCell align="center" sx={{ bgcolor: 'WhiteSmoke' }}>Market</TableCell>
                <>
                  {
                    modelSelection.map(modelIds =>
                      <TableCell key={modelIds.join('-')} align="center" sx={{ width: '100px', }}>
                        {modelIds.map(modelId => <div>{modelId}</div>)}
                      </TableCell>)
                  }
                </>
              </TableRow>
            </TableHead>
            <TableBody sx={{ bgcolor: 'white' }}>
              {!loading && <ReleaseTableRows/>}
            </TableBody>
          </Table>
        }
      </TableContainer>
    </>
  )
}

