export class MarketAggregation {

  constructor(ddbMarket) {
    this.language = ddbMarket.lang?.S
    this.ecoGroupCreationTime = ddbMarket.timestampEcoGroup?.N
    this.ecoGroupModels = ddbMarket.ecoGroupModels?.L?.map(m => m.S)
    this.modelListCreationTime = ddbMarket.timestampModelList.N
    this.modelListModels = ddbMarket.listModels?.L?.map(m => m.S)
    this.marketStructureCreationTime = ddbMarket.timestampStructure?.N
    this.marketStructureModels = ddbMarket.structureModels?.L?.map(m => m.S)
  }

  modelListOf(modelType) {
    return this.modelListModels?.includes(modelType) ? this.modelListCreationTime : null
  }

  marketStructureOf(modelType) {
    return this.marketStructureModels?.includes(modelType) ? this.marketStructureCreationTime : null
  }

  ecoGroupOf(modelType) {
    return this.ecoGroupModels?.includes(modelType) ? this.ecoGroupCreationTime : null
  }
}
