import { TableCell, TableRow } from '@mui/material'
import { Model } from '../models/model'
import { AvailabilityTip } from '../styled/AvailabilityTip'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { modelSelectionState, onlyUnreleasedState, searchKeywordState } from './state-store'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { localCompareUndefinedLast } from '../utils'

const icon = status =>
  status ? <CheckCircleOutlineIcon color="success"/> : <CancelOutlinedIcon color="error"/>

const modelUrl = (market, modelId, language) =>
  `https://mpi.pccompare.aws.porsche-preview.cloud/markets/${market}/models/${modelId}?lang=${language}`

export const MarketRow = ({ market, language, models }) => {

  if (!models) {
    models = []
  }

  const modelSelection = useRecoilValue(modelSelectionState)

  const onlyUnreleased = useRecoilValue(onlyUnreleasedState)

  const searchKeyword = useRecoilValue(searchKeywordState)

  const findReleasedModels = releaseModelGroup => {
    return models.filter(m => releaseModelGroup.some(id => m.modelTypeYear.startsWith(id)))
  }

  const fullyReleased = modelSelection.every(group => findReleasedModels(group).some(m => m.availability().status))

  if (onlyUnreleased && fullyReleased) {
    return <></>
  }

  const rowKeyword = '' + models.map(m => m.key).join(' ') + market + language
  const searchCriteriaMatches = rowKeyword.toLowerCase().includes(searchKeyword.toLowerCase())
  if (searchKeyword && !searchCriteriaMatches) {
    return <></>
  }

  const onDevPrint = model => console.log('Dev Print model', model)

  return (
    <TableRow id="release-table-row" key={`${market}-${language}`}>
      <TableCell sx={{ bgcolor: 'WhiteSmoke', fontWeight: 'bold', width: '30px' }}>
        <center>{market}</center>
        <center>{language}</center>
      </TableCell>
      <>
        {
          modelSelection.map(releaseGroup => {
            const withYearSelection = releaseGroup[0].includes('-')
            const cellModels = findReleasedModels(releaseGroup)
            const model = (cellModels.length > 0 ? cellModels : [ new Model() ])
              .sort((a, b) =>
                localCompareUndefinedLast(a.presentationAvailable, b.presentationAvailable)
                || b.modelYear - a.modelYear
              )
              [0]

            const { tips, status } = model.availability()
            return (
              <AvailabilityTip key={releaseGroup.join('-')} tips={tips} onDevPrint={() => onDevPrint(model)}>
                <TableCell align="center"
                           sx={{ width: '100px', verticalAlign: 'top' }}
                           onClick={() => !model.missing && window.open(modelUrl(market, model.modelTypeYear, language))}>
                  {icon(status)}
                  {withYearSelection || <p>{model?.modelYear}</p>}
                  <p>{model?.modelName}</p>
                </TableCell>
              </AvailabilityTip>
            )
          })
        }
      </>
    </TableRow>
  )

}
