import axios from "axios"
import { env } from './env'

const { frontendBaseHost, cognito } = env
const { clientId, domain } = cognito

const authorizeEndpoint = `https://${domain}/oauth2/authorize`
const tokenEndpoint = `https://${domain}/oauth2/token`
const redirectUri = `https://${frontendBaseHost}/auth/redirect`
const scope = "openid profile"

const STORAGE_PREFIX = `CognitoIdentityServiceProvider.${clientId}`

export const getAccessToken = () => localStorage.getItem(`${STORAGE_PREFIX}.accessToken`)

export const getIdToken = () => localStorage.getItem(`${STORAGE_PREFIX}.idToken`)

export const redeemCodeForTokens = (code, onError) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const tokenRequestBody = new URLSearchParams({
    client_id: clientId,
    code_verifier: window.sessionStorage.getItem("code_verifier"),
    grant_type: "authorization_code",
    redirect_uri: redirectUri,
    code,
    scope
  })
  axios.post(tokenEndpoint, tokenRequestBody, { headers })
    .then((response) => {
      if (response.status !== 200) {
        onError({ response })
        return
      }
      const { id_token, access_token } = response.data
      localStorage.setItem(`${STORAGE_PREFIX}.idToken`, id_token)
      localStorage.setItem(`${STORAGE_PREFIX}.accessToken`, access_token)
      window.location = window.sessionStorage.getItem("redirect_to") || "/dev"
    }).catch(onError)
}

export const login = async function (redirectTo) {
  const codeVerifier = generateRandomString(64)

  window.sessionStorage.setItem("code_verifier", codeVerifier)
  if (redirectTo) window.sessionStorage.setItem("redirect_to", redirectTo)
  const codeChallenge = await generateCodeChallenge(codeVerifier)

  const args = new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    code_challenge_method: "S256",
    code_challenge: codeChallenge,
    redirect_uri: redirectUri
  })
  window.location.href = authorizeEndpoint + "/?" + args
}

const generateCodeChallenge = async (codeVerifier) => {
  const digest = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(codeVerifier))

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const generateRandomString = (length) => {
  let text = ""
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}
