import { useRecoilState } from 'recoil'
import { modelSelectionState } from './state-store'
import ChipDelete from '@mui/joy/ChipDelete'
import { Chip } from '@mui/joy'

export const ModelList = () => {

  const [ modelSelection, setModelSelection ] = useRecoilState(modelSelectionState)

  const handleDeselectModel = deleted => {
    setModelSelection(oldValue => oldValue.filter(g => g[0] !== deleted[0]))
  }

  return <div className="release-model-list-chips">
    {
      modelSelection.map(selectionGroup => {
        return <div className="release-model-chip">
          <Chip size="sm" variant="outlined" color="neutral"
                endDecorator={<ChipDelete onDelete={() => handleDeselectModel(selectionGroup)}/>}
          >
            {selectionGroup.length > 1 ? selectionGroup[0] + '/1' : selectionGroup[0]}
          </Chip>
        </div>
      })
    }
  </div>
}
