import { FormControl, Input, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useRef, useState } from 'react'
import { useRecoilState } from 'recoil'

export const SearchBar = ({ keywordState }) => {

  const [ searchKeyword, setSearchKeyword ] = useRecoilState(keywordState)

  const [ input, setInput ] = useState('')

  const timer = useRef(undefined)

  const handleInputChange = e => {
    clearTimeout(timer.current)
    setInput(e.target.value)
    timer.current = setTimeout(() => setSearchKeyword(e.target.value), 400)
  }

  const clearIcon = () => <ClearIcon onClick={() => setSearchKeyword('')}
                                     style={{ cursor: 'default', paddingRight: '8px' }}/>

  return <FormControl sx={{ width: 1 }}>
    <Input
      id="search-anything-of-release-table"
      placeholder="Search for anything ..."
      sx={{ fontSize: 'smaller' }}
      value={input ?? searchKeyword}
      onChange={handleInputChange}
      startAdornment={
        <InputAdornment position="start" sx={{ paddingLeft: 1 }}>
          {searchKeyword ? clearIcon() : <SearchIcon/>}
        </InputAdornment>
      }
    />
  </FormControl>

}
