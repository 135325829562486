import React from 'react'
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { ModelRow } from './ModelRow'
import { SearchBar } from '../SearchBar'
import { loadingState, modelsSelector, paginatedModelsSelector, pagingState, searchKeywordState } from './state-store'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useModels } from './useModels'
import { Options, usePreference } from '../profile/preferences'

const ModelsTable = () => {

  const loading = useRecoilValue(loadingState)

  const [ paging, setPaging ] = useRecoilState(pagingState)

  useModels()

  const [ showDomain ] = usePreference(Options.SHOW_DOMAIN_LAYER)

  const models = useRecoilValue(modelsSelector)

  const paginatedModels = useRecoilValue(paginatedModelsSelector)

  const handlePageChange = (event, newPage) => {
    setPaging({ ...paging, page: newPage })
  }

  const pagingInfo = ({ from, to, count }) => {
    if (count === 0) {
      return 'No models found'
    } else {
      return `${from}-${to} of ${count}`
    }
  }

  return (
    <>
      <SearchBar keywordState={searchKeywordState}/>
      <TableContainer sx={{ height: 0.9 }}>
        {loading ? <LinearProgress className="mpi-loading-bar"/> : <></>}
        <Table stickyHeader size="small" aria-label="sticky dense table">
          <TableHead>
            <TableRow>
              <TableCell>Market</TableCell>
              <TableCell align="center">Model<br/>Name</TableCell>
              <TableCell align="center">Model<br/>Type</TableCell>
              <TableCell align="center">Range</TableCell>
              <TableCell align="center">Series</TableCell>
              <TableCell align="center">Body<br/>Type</TableCell>
              <TableCell align="center">Engine<br/>Type</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Price<br/>Disclaimer</TableCell>
              <TableCell align="center">Technical<br/>Data</TableCell>
              <TableCell align="center">Economy<br/>Data</TableCell>
              <TableCell align="center">PCCD</TableCell>
              <TableCell align="center">PS5</TableCell>
              <TableCell align="center">SitecorePush</TableCell>
              <TableCell align="center">SitecorePull</TableCell>
              <TableCell align="center" hidden={!showDomain}>MPI<br/>Domain</TableCell>
              <TableCell align="center">API<br/>Model</TableCell>
              <TableCell align="center">API<br/>ModelList</TableCell>
              <TableCell align="center">API<br/>Market</TableCell>
              <TableCell align="center">API<br/>EcoGroup</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              paginatedModels
                .map(model => <ModelRow key={model.key} model={model}/>)
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        labelDisplayedRows={pagingInfo}
        component="div"
        count={models.length}
        rowsPerPage={paging.rowsPerPage}
        page={paging.page}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default ModelsTable
