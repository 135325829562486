import React from 'react'
import Paper from '@mui/material/Paper'
import { PccdConfig } from './PccdConfig'
import './style.css'
import { SidePanel } from './SidePanel'

export const ConfigCenter = () => {

  return <div className="d-flex flex-nowrap w-100">
    <SidePanel/>
    <Paper sx={{ width: '100%', overflow: 'auto', textAlign: 'left' }}>
      <PccdConfig/>
    </Paper>
  </div>

}
