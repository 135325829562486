import { MarketSelector } from '../market-selector'
import { marketConfigState, selectedMarketsState } from './state-store'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

export const SidePanel = () => {

  const marketConfig = useRecoilValue(marketConfigState)

  const [ fallback, setFallback ] = useState('')

  useEffect(() => {
    const fallbackText = () => {
      if (marketConfig?.market === 'default') {
        return '* This market falls back to default configuration.'
      }
    }
    setFallback(fallbackText())
  }, [ marketConfig, setFallback ])

  return <div className="side-panel">
    <MarketSelector marketSelectionState={selectedMarketsState}
                    maxSelections={1}
    />
    {fallback && <div className="using-default-config">{fallback}</div>}
  </div>
}
