import React, { useEffect } from 'react'
import { onlyUnreleasedState } from './state-store'
import { useRecoilState } from 'recoil'
import { Checkbox, Divider } from '@mui/joy'
import { intro, releasePageSelectorIntroKey } from '../intro'
import { ModelBatches } from './ModelBatches'
import { ModelsInput } from './ModelsInput'
import { ModelList } from './ModelList'

export const SidePanel = () => {

  const [ onlyUnreleased, setOnlyUnreleased ] = useRecoilState(onlyUnreleasedState)

  useEffect(() => {
    intro(releasePageSelectorIntroKey)
  })

  return (
    <>
      <Checkbox label="Only Unreleased"
                size="sm"
                id="release-only-unreleased-checkbox"
                checked={onlyUnreleased}
                onChange={e => setOnlyUnreleased(e.target.checked)}
                sx={{ width: '100%', marginY: '16px', fontSize: 'smaller' }}
      />
      <ModelList></ModelList>
      <Divider sx={{ marginBottom: '8px' }}>Select Models</Divider>
      <ModelsInput/>
      <Divider>Release Batches</Divider>
      <ModelBatches/>
    </>
  )
}
