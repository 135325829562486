import { queryMarketModels, queryModels } from '../DdbClient'
import { MarketAggregation } from './market-aggregation'
import { Model } from './model'
import { localCompareUndefinedLast } from '../utils'

const groupBy = (xs, f) => xs.reduce(function (rv, x) {
  (rv[f(x)] = rv[f(x)] || []).push(x)
  return rv
}, {})

const compare = (a, b) => {
  return a.market.localeCompare(b.market) ||
    a.language.localeCompare(b.language) ||
    a.indexOfRange() - b.indexOfRange() ||
    localCompareUndefinedLast(a.modelSeries, b.modelSeries) ||
    a.modelType.localeCompare(b.modelType) ||
    b.modelYear - a.modelYear
}

export const getModelsByMarket = async market => {
  const ddbModels = await queryMarketModels('blue', market)
  if (ddbModels.length === 0) {
    return []
  }

  const aggregations = Object.fromEntries(ddbModels.filter(model => model?.modelTypeYearLang?.S.startsWith('aggregate'))
    .map(m => new MarketAggregation(m))
    .map(market => [ market.language, market ]))

  const validModels = ddbModels.filter(model => !model?.modelTypeYearLang?.S?.startsWith('aggregate'))
    .map(m => new Model(m, aggregations[m.lang?.S]))
    .filter(m => m.isValid())

  return Object.values(groupBy(validModels, m => `${m.market}-${m.language}-${m.modelType}`))
    .flat()
    .sort(compare)
}

export const getModelsById = async modelId => {
  const [ modelType, modelYear ] = modelId.split('-')

  const ddbModels = await queryModels('blue', modelType)
  if (ddbModels.length === 0) {
    return []
  }

  return ddbModels.filter(model => !model?.modelTypeYearLang?.S?.startsWith('aggregate'))
    .map(m => new Model(m))
    .filter(m => m.isValid())
    .filter(m => !modelYear || m.modelYear === modelYear)
}
