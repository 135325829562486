import { useRecoilState } from 'recoil'
import { preferencesState } from '../state-store'

export const Options = Object.freeze({

  SHOW_DOMAIN_LAYER: {
    key: 'ShowDomainLayer',
    defaultValue: false,
    finalizer: value => typeof value === 'string' ? value === 'true' : value,
  },

  SHOW_ENV_BUTTON: {
    key: 'ShowEnvButton',
    defaultValue: false,
    finalizer: value => typeof value === 'string' ? value === 'true' : value,
  },

  DEV_PRINT_MODEL: {
    key: 'DevPrintModel',
    defaultValue: false,
    finalizer: value => typeof value === 'string' ? value === 'true' : value,
  },
})

export const usePreference = option => {
  const [ preference, setPreference ] = useRecoilState(preferencesState(option.key))
  return [ (option.finalizer ?? (x => x))(preference), setPreference ]
}
