import { useRecoilValue } from 'recoil'
import { modelsState } from './state-store'
import React, { useEffect } from 'react'
import { intro, releasePageTableIntroKey } from '../intro'
import { allMarketsState } from '../state-store'
import { MarketRow } from './MarketRow'

export const ReleaseTableRows = () => {

  const allMarkets = useRecoilValue(allMarketsState)

  const models = useRecoilValue(modelsState)

  useEffect(() => {
    intro(releasePageTableIntroKey)
  })

  const marketLanguages = allMarkets.map(market => market.languages
    .map(language => ({ language, market: market.market })))
    .flat()

  return (
    <>
      {
        marketLanguages.map(({ language, market }) => {
          const marketLanguageModels = models.filter(m => m.market === market && m.language === language)
          return marketLanguageModels ?
            <MarketRow key={`${market}-${language}`}
                       market={market}
                       language={language}
                       models={marketLanguageModels}
            /> : <></>
        })
      }
    </>
  )
}
