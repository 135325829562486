import { driver } from 'driver.js'
import releasePageSelector from './release-page-selector.json'
import releasePageTable from './release-page-table.json'
import "driver.js/dist/driver.css"

export const releasePageSelectorIntroKey = 'releasePageSelector'
export const releasePageTableIntroKey = 'releasePageTable'

const introductions = {
  releasePageSelector,
  releasePageTable,
}

const finishedIntro = key => {
  return localStorage.getItem(`intro.${key}`) === 'true'
}

const finishIntro = key => localStorage.setItem(`intro.${key}`, 'true')

export const intro = introKey => {
  if (finishedIntro(introKey)) {
    return
  }
  const driverObj = driver({
    ...introductions[introKey],
    onDestroyed: () => finishIntro(introKey),
  })
  driverObj.drive()
}
