export const releases = [
  {
    id: '718-switch',
    name: '718 Switch',
    date: "Nov 24",
    models: [
      [ '982120', '982121' ],
      [ '982320', '982321' ],
      [ '982110', '982111' ],
      [ '982310', '982311' ],
      [ '982130', '982131' ],
      [ '982330', '982331' ],
      [ '982160', '982161' ],
      [ '982360', '982361' ],
      [ '982850', '982851' ],
      [ '982890', '982891' ],
    ],
  },
  {
    id: '991-gt-2025',
    name: '911 GT',
    date: "Oct 24",
    models: [
      [ "992812-2025" ],
      [ "992822-2025" ],
    ],
  },
  {
    id: '991-turbo-2025',
    name: '911 Turbo 50Y',
    date: "Aug 24",
    models: [
      [ "992470-2025" ],
    ],
  },
  {
    id: 'cayenne-2025',
    name: 'Cayenne',
    date: "Sep 24",
    models: [
      [ "9YBAI1-2025" ],
      [ "9YBAV1-2025" ],
      [ "9YBBS1-2025" ],
      [ "9YBBJ1-2025" ],
      [ "9YBBN1-2025" ],
      [ "9YBCT1-2025" ],
      [ "9YBCZ1-2025" ],
      [ "9YBCP1-2025" ],
      [ "9YAAI1-2025" ],
      [ "9YAAV1-2025" ],
      [ "9YABS1-2025" ],
      [ "9YABJ1-2025" ],
      [ "9YABN1-2025" ],
      [ "9YACT1-2025" ],
    ],
  },
  {
    id: 'panamera-2025',
    name: 'Panamera',
    date: "Jun 24",
    models: [
      [ "YAADG1-2025" ],
      [ "YAAFH1-2025" ],
      [ "YABFH1-2025" ],
    ],
  },
  {
    id: '911-2025',
    name: '911',
    date: "Apr 24",
    models: [
      [ "9921B2-2025" ],
      [ "9923B2-2025" ],
      [ "992142-2025" ],
      [ "992342-2025" ],
      [ "992442-2025" ],
      [ "992542-2025" ],
      [ "992642-2025" ],
      [ "992830-2025" ],
    ],
  },
  {
    id: 'taycan-2025',
    name: 'Taycan 2025',
    date: "Feb 24",
    models: [
      [ "Y1AAI1-2025" ],
      [ "Y1AFP1-2025" ],
      [ "Y1AFT1-2025" ],
      [ "Y1ADJ1-2025" ],
      [ "Y1AFL1-2025" ],
      [ "Y1AFM1-2025" ],
      [ "Y1BBN1-2025" ],
      [ "Y1BDJ1-2025" ],
      [ "Y1BFL1-2025" ],
      [ "Y1BFM1-2025" ],
      [ "Y1CAI1-2025" ],
      [ "Y1CDJ1-2025" ],
      [ "Y1CFL1-2025" ],
      [ "Y1CFM1-2025" ],
    ],
  },
  {
    id: 'macan-2025',
    name: 'Macan 2025',
    date: "Feb 24",
    models: [
      [ "XABBB1-2025" ],
      [ "XABFD1-2025" ],
      [ "XABAA1-2025" ],
      [ "XABDC1-2025" ],
    ],
  },
]
