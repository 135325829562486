import { Table } from '@mui/joy'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { marketConfigState } from './state-store'

export const EconomyData = ({ ecoStandard }) => {

  const marketConfig = useRecoilValue(marketConfigState)

  const ecoConfig = marketConfig?.config ? marketConfig.config[ecoStandard] : null

  if (!ecoConfig) {
    return <></>
  }

  const parseAttributeDecorator = decorator => {
    switch (decorator) {
      case 'combineRangesMinMax':
      case 'energyEfficiencyAscending':
        return {
          direction: 'Min-to-Max'
        }
      case 'combineRangesMaxMin':
      case 'energyEfficiencyDescending':
        return {
          direction: 'Max-to-Min'
        }
      default:
        return {
          direction: decorator
        }
    }
  }

  return (
    <div className="pb-4">
      <h4>Economy Data Configurations ({ecoStandard.toUpperCase()})</h4>
      <Table aria-label="dense table">
        <thead>
        <tr>
          <th>Attribute</th>
          <th>Direction</th>
          <th>Attributes</th>
        </tr>
        </thead>
        <tbody>
        {
          Object.entries(ecoConfig)
            .map(([ fieldName, attributes ]) => {
                const [ decorator, attributeIds ] = Object.entries(attributes)[0]
                const { direction } = parseAttributeDecorator(decorator)
                return (
                  <tr key={fieldName}>
                    <td>{fieldName}</td>
                    <td>{direction}</td>
                    <td>{attributeIds.map(id => <p className="mb-0">{id}</p>)}</td>
                  </tr>
                )
              }
            )
        }
        </tbody>
      </Table>
    </div>
  )

}
