import { atom, selector } from 'recoil'
import { localstorageRepo } from '../localstorageRepo'
import { releases } from './releases'

const atomNamespace = key => `app.releases.${key}`

export const modelSelectionState = selector({
  key: atomNamespace('modelSelectionState'),
  get: ({ get }) => {
    const inputModels = [ ...new Set(get(inputModelsState)) ].map(m => [ m ])
    const releaseBatchModels = releases.find(r => r.id === get(releaseBatchSelectionState))?.models ?? []
    return [ ...inputModels, ...releaseBatchModels ]
  },
  set: ({ set }, newValue) => {
    set(inputModelsState, newValue)
    set(releaseBatchSelectionState, undefined)
  }
})

export const inputModelsState = atom({
  key: atomNamespace('inputModelsState'),
  default: [],
})

export const releaseBatchSelectionState = atom({
  key: atomNamespace('releaseSelectionState'),
  default: undefined,
})

export const loadingState = atom({
  key: atomNamespace('loadingState'),
  default: false,
})

export const onlyUnreleasedState = atom({
  key: atomNamespace('onlyUnreleasedState'),
  default: localstorageRepo.get('app.releases.onlyUnreleased') === 'true' ?? false,
  effects: [
    ({ onSet }) => {
      onSet(newValue => {
        localstorageRepo.set('app.releases.onlyUnreleased', newValue)
      })
    },
  ],
})

export const searchKeywordState = atom({
  key: atomNamespace('ReleaseTableSearchKeywordState'),
  default: '',
})

export const modelsState = atom({
  key: atomNamespace('modelsState'),
  default: [],
})
