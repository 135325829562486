import React from 'react'
import { Checkbox, List, ListItem } from '@mui/joy'

const listItemProps = {
  '--List-gap': '8px',
  '--ListItem-radius': '20px',
  '--ListItem-minHeight': '28px',
  '--ListItem-gap': '4px',
}

const checkBoxSlotProps = {
  action: ({ checked }) => ({
    sx: checked && {
      border: '1px solid',
      borderColor: 'primary.500',
    }
  }),
}

export const ChipList = ({ options, values, onChange }) => {

  const onSelect = (event, value) => {
    if (event.target.checked) {
      onChange(val => [ ...val, value ])
    } else {
      onChange(val => val.filter((v) => v !== value))
    }
  }

  return (
    <List orientation="horizontal" wrap sx={listItemProps}>
      {options.map(
        value => (
          <ListItem key={value}>
            <Checkbox size="sm" disableIcon overlay label={value}
                      sx={{
                        '--joy-fontSize-sm': '0.75rem',
                      }}
                      checked={values.includes(value)}
                      variant={values.includes(value) ? 'soft' : 'outlined'}
                      onChange={event => onSelect(event, value)}
                      slotProps={checkBoxSlotProps}
            />
          </ListItem>
        ),
      )}
    </List>
  )
}
