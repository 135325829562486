import { FormControl, Switch } from '@mui/joy'
import { usePreference } from './preferences'
import './style.css'

export const SwitchButton = ({ option, label }) => {

  const [ preference, setPreference ] = usePreference(option)

  const onChange = event => setPreference(event.target.checked)

  return <FormControl orientation="horizontal" className="w-50 mx-auto my-2">
    <div className="w-50">
      <p className="profile-option-label">{label}</p>
    </div>
    <Switch checked={!!preference} onChange={onChange}/>
  </FormControl>

}
