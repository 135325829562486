import * as React from 'react'
import { ChipList } from '../../styled/ChipList'
import { useRecoilState } from 'recoil'
import { selectedRangesState } from '../state-store'
import { ranges } from '../../models/model'

export const RangeSelector = () => {

  const [ selectedRanges, setSelectedRanges ] = useRecoilState(selectedRangesState)

  return (
    <>
      <p className="language-selector-title mt-4">Ranges</p>
      <ChipList values={selectedRanges}
                options={ranges}
                onChange={setSelectedRanges}/>
    </>
  )
}
