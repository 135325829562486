import React, { useEffect } from "react"
import ModelsTable from "./ModelsTable"
import Paper from '@mui/material/Paper'
import { RangeSelector } from "./filters/RangeSelector"
import { MarketSelector } from '../market-selector'
import { availableLanguagesSelector, selectedLanguagesState, selectedMarketsState } from './state-store'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { ChipList } from '../styled/ChipList'
import './style.css'

const Dashboard = () => {

  const availableLanguages = useRecoilValue(availableLanguagesSelector)

  const [ selectedLanguages, setSelectedLanguages ] = useRecoilState(selectedLanguagesState)

  const resetSelectedLanguages = useResetRecoilState(selectedLanguagesState)

  const selectedMarkets = useRecoilValue(selectedMarketsState)

  useEffect(() => {
    resetSelectedLanguages()
  }, [ selectedMarkets, resetSelectedLanguages ])

  return <div className="d-flex flex-nowrap w-100">
    <div className="side-panel">
      <MarketSelector marketSelectionState={selectedMarketsState} maxSelections={4}/>
      {
        availableLanguages.length > 0 && <>
          <p className="language-selector-title">Languages</p>
          <ChipList values={selectedLanguages}
                    options={availableLanguages}
                    onChange={setSelectedLanguages}/>
        </>
      }
      <RangeSelector/>
    </div>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <ModelsTable/>
    </Paper>
  </div>
}

export default Dashboard
