import { SwitchButton } from './switch-button'
import { Options } from './preferences'

export const Profile = () => {

  return <div className="my-5">
    <SwitchButton label="Show domain layer download"
                  option={Options.SHOW_DOMAIN_LAYER}/>
    <SwitchButton label="Show env switch button"
                  option={Options.SHOW_ENV_BUTTON}/>
    <SwitchButton label="Allow developer to print model"
                  option={Options.DEV_PRINT_MODEL}/>
  </div>
}
