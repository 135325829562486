export class Model {

  constructor(ddbModel, aggregation) {
    if (!!ddbModel) {
      this.market = ddbModel.market?.S
      this.language = ddbModel.lang?.S
      this.modelType = ddbModel.modelType?.S
      this.modelYear = ddbModel.modelYear?.S
      this.modelName = ddbModel.modelName?.S
      this.modelRange = ddbModel.modelRange?.S
      this.modelSeries = ddbModel.modelSeries?.S
      this.bodyType = ddbModel.bodyType?.S
      this.engineType = ddbModel.engineType?.S
      this.price = ddbModel.price?.S
      this.priceDisclaimers = ddbModel.priceDisclaimers?.L?.length
      this.pressEmbargo = ddbModel.pressEmbargo?.S
      this.iccInvisible = ddbModel.iccInvisible?.BOOL
      this.mpiEnabled = ddbModel.mpiEnabled?.BOOL
      this.mpiDisabled = ddbModel.mpiDisabled?.BOOL
      this.technicalDetail = ddbModel.technicalDetail?.BOOL
      this.economyDetail = ddbModel.economyDetail?.BOOL
      this.presentationAvailable = ddbModel.presentationAvailable?.BOOL
      this.pccdCreationTime = ddbModel.timestampPccd?.N
      this.ps5CreationTime = ddbModel.timestampSingleModel?.N
      this.sitecorePushCreationTime = ddbModel.timestampPs5?.N
      this.sitecorePullCreationTime = ddbModel.timestampSitecorePush?.N
      this.singleModelCreationTime = ddbModel.timestampSitecorePull?.N
    } else {
      this.missing = true
    }
    this.modelListCreationTime = aggregation?.modelListOf(this.modelType)
    this.marketStructureCreationTime = aggregation?.marketStructureOf(this.modelType)
    this.ecoGroupCreationTime = aggregation?.ecoGroupOf(this.modelType)

    this.key = [
      this.market,
      this.language,
      this.modelType,
      this.modelYear,
      this.modelName,
      this.modelRange,
      this.modelSeries,
    ]
      .filter(s => !!s)
      .map(s => s.toLowerCase()).join('-')

    this.modelTypeYear = `${this.modelType}-${this.modelYear}`
  }

  isValid = () => {
    return this.market && this.language && this.modelType && this.modelYear
  }

  indexOfRange = () => {
    if (!this.modelRange) {
      return Number.MAX_SAFE_INTEGER
    }
    return ranges.map(r => r.toLowerCase()).indexOf(this.modelRange.toLowerCase())
  }

  pressEmbargoBlocking = () => {
    const today = new Date().getTime()
    const modelPublishDate = new Date(this.pressEmbargo).getTime()
    return !!this.pressEmbargo && today < modelPublishDate
  }

  availability = () => {
    const pressEmbargoBlocking = this.pressEmbargoBlocking()
    return this.tipGenerator({
      missing: this.missing,
      pccdCreationTime: this.pccdCreationTime,
      pressEmbargo: pressEmbargoBlocking ? this.pressEmbargo : null,
      mpiEnabled: this.mpiEnabled,
      iccInvisible: this.iccInvisible,
      mpiDisabled: this.mpiDisabled,
      presentationAvailable: this.presentationAvailable,
    })
  }

  tipGenerator = (
    {
      missing,
      pccdCreationTime,
      pressEmbargo,
      mpiEnabled,
      iccInvisible,
      mpiDisabled,
      presentationAvailable,
    }) => {
    if (missing || (!pccdCreationTime && !presentationAvailable)) {
      return { tips: [ `PCCD hasn't released` ], status: false }
    }
    const tips = []
    const status = !!presentationAvailable
    if (pressEmbargo) {
      tips.push([ `Press embargo blocking until ${pressEmbargo}` ])
      if (mpiEnabled) {
        return { tips, status }
      }
      if (iccInvisible) {
        tips.push('Pccd set ICC invisible')
        return { tips, status }
      }
      if (mpiDisabled) {
        tips.push('Disabled by MPI')
        return { tips, status }
      }
      return { tips, status }
    }
    if (mpiEnabled) {
      return { tips: [ 'Enabled by MPI' ], status }
    }
    if (iccInvisible) {
      tips.push('Pccd set ICC invisible')
    }
    if (mpiDisabled) {
      tips.push('Disabled by MPI')
    }
    if (tips.length > 0) {
      return { tips, status }
    }

    if (!presentationAvailable) {
      return { tips: [ 'Please reach out to MPI team' ], status }
    }
    return { tips: [], status }
  }
}

export const ranges = [ '718', 'Taycan', 'Panamera', '911', 'Macan', 'Cayenne' ]
