import { getIdToken } from './PPNCognitoService'

function parseJwt(token) {
  if (!/(^[\w-]*\.[\w-]*\.[\w-]*$)/.test(token)) {
    return {}
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('')
    .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
    .join(''))
  return JSON.parse(jsonPayload)
}

export const getSub = () => parseJwt(getIdToken()).sub

const set = (key, value) => localStorage.setItem(`${getSub()}.${key}`, value)

const get = (key) => {
  try {
    return localStorage.getItem(`${getSub()}.${key}`)
  } catch (e) {
    return null
  }
}

export const localstorageRepo = { get, set }


