import { atom } from 'recoil'

const atomNamespace = key => `app.config-center.${key}`

export const selectedMarketsState = atom({
  key: atomNamespace('selectedMarketsState'),
  default: [],
})

export const marketConfigState = atom({
  key: atomNamespace('marketConfigState'),
  default: {},
})
