import { atom, selector } from 'recoil'

const namespace = key => `app.dashboard.${key}`

export const loadingState = atom({
  key: namespace('loadingState'),
  default: false,
})

export const searchKeywordState = atom({
  key: namespace('searchKeywordState'),
  default: '',
})

export const selectedMarketsState = atom({
  key: namespace('selectedMarketsState'),
  default: [],
})

export const availableLanguagesSelector = selector({
  key: namespace('availableLanguagesSelector'),
  get: ({ get }) => {
    const selectedMarkets = get(selectedMarketsState)
    return [ ...new Set(selectedMarkets.map(market => market.languages).flat()) ]
  },
})

export const marketNamesSelector = selector({
  key: namespace('marketNamesSelector'),
  get: ({ get }) => {
    const selectedMarkets = get(selectedMarketsState)
    return [ ...new Set(selectedMarkets.map(market => market.market)) ]
  },
})

export const selectedLanguagesState = atom({
  key: namespace('selectedLanguagesState'),
  default: [],
})

export const selectedRangesState = atom({
  key: namespace('selectedRangesState'),
  default: [],
})

export const modelsState = atom({
  key: namespace('modelsState'),
  default: {},
})

export const pagingState = atom({
  key: namespace('pagingState'),
  default: {
    page: 0,
    rowsPerPage: 300,
  },
})

export const modelsSelector = selector({
  key: namespace('modelsSelector'),
  get: ({ get }) => {
    const models = Object.values(get(modelsState)).flat()
    const languages = get(selectedLanguagesState)
    const ranges = get(selectedRangesState)
    const searchKeyword = get(searchKeywordState)
    return Object.values(models)
      .filter(model => !!model && model.availability().status)
      .filter(model => languages.length === 0 || languages.includes(model.language))
      .filter(model => ranges.length === 0 || ranges.includes(model.modelRange))
      .filter(model => !searchKeyword || !searchKeyword.trim() || model.key.includes(searchKeyword.trim()))
  },
})

export const paginatedModelsSelector = selector({
  key: namespace('paginatedModelsSelector'),
  get: ({ get }) => {
    const models = get(modelsSelector)
    const { page, rowsPerPage } = get(pagingState)
    return models.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  },
})
