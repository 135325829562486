import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { fetchVmceDashboardData, preSignUrl } from './BffClient'
import { env } from './env'
import './Vmce.css'
import axios from 'axios'

const VmceDashboard = () => {

  const { stage } = useParams()

  const [ vmce, setVmce ] = useState([])

  const [ metadata, setMetadata ] = useState({})

  useEffect(() => {
    fetchVmceDashboardData(stage)
      .then(response => {
        if (!response.metadata) {
          setVmce(response)
        } else {
          setVmce(response.contents)
          setMetadata(response.metadata)
        }
      })
  }, [ stage ])

  const save = (response, key) => {
    const blob = new Blob([ response.data ], { type: response.headers['content-type'] })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${key}-${new Date().toISOString().substring(0, 19)}.json`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const download = ({ bucket, key }) => {
    return preSignUrl(bucket, key)
      .then(signedUrl => axios.get(signedUrl, { responseType: 'blob' }))
      .then(response => save(response, key))
  }

  const openContent = key => {
    const bucket = `vmce-node-${stage}-${env.awsAccountId}-eu-west-1`
    const confirm = window.confirm(`Open VMCE content for "${key}"?`)
    confirm && download({ bucket, key })
  }

  const updating = () => metadata.numberOfVisibleMessages && metadata.numberOfVisibleMessages !== '0'

  const contentStatus = () => {
    return updating() ?
      <span className="vmce-status-updating">Updating</span> :
      <span className="vmce-status-uptodate">UpToDate</span>
  }

  const etf = () => metadata.numberOfVisibleMessages / 75 | 0

  return <div className="scrollarea">
    <div className="vmce-status">
      <h3>VMCE Content Status: {contentStatus()}</h3>
      {updating() && <h5>Expect to finish in {etf()} minutes</h5>}
    </div>
    <table className="data-table mpi">
      <thead>
      <tr className="mpi">
        <th className="mpi col-2"><p>LanguageCode</p></th>
        <th className="mpi col-4"><p>UpdatedTime</p></th>
      </tr>
      </thead>
      <tbody className="mpi">
      {
        vmce.map(v =>
          <tr className="mpi" key={v.Key}>
            <td className="clickable mpi" onClick={() => openContent(v.Key)}><p>{v.Key.replace('vmce/', '')}</p></td>
            <td className="mpi update-time"><p>{v.LastModified}</p></td>
          </tr>
        )
      }
      </tbody>
    </table>
  </div>

}

export default VmceDashboard
