import { Table } from '@mui/joy'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { marketConfigState } from './state-store'

export const TechnicalData = () => {

  const marketConfig = useRecoilValue(marketConfigState)

  const technicalDetailsConfig = marketConfig?.config?.technicalDetail

  if (!technicalDetailsConfig) {
    return <></>
  }

  return (
    <div>
      <h4>Technical Data</h4>
      <Table aria-label="dense table">
        <thead>
        <tr>
          <th>Attribute</th>
          <th>Attributes</th>
        </tr>
        </thead>
        <tbody>
        {
          Object.entries(technicalDetailsConfig)
            .map(([ fieldName, attributes ]) => {
                const [ , attributeIds ] = Object.entries(attributes)[0]
                return (
                  <tr key={fieldName}>
                    <td>{fieldName}</td>
                    <td>{attributeIds.map(id => <p className="mb-0">{id}</p>)}</td>
                  </tr>
                )
              }
            )
        }
        </tbody>
      </Table>
    </div>
  )

}
